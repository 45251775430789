$( function() {
    const wind = $(window)

    wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 200,
      mobile: false,
      live: false,
    })
    wow.init()

    $.scrollIt({
      upKey: 38,                // key code to navigate to the next section
      downKey: 40,              // key code to navigate to the previous section
      easing: 'linear',         // the easing function for animation
      scrollTime: 400,          // how long (in ms) the animation takes
      activeClass: 'active',    // class given to the active nav element
      onPageChange: null,       // function(pageIndex) that is called when page is changed
      topOffset: -80,           // offset (in px) for fixed top navigation
    })

    const navbar = document.querySelector('[data-js-navbar]')
    const navbarNav = document.querySelector('[data-js-navbar-nav]')
    const navbarToggler = document.querySelector('[data-js-navbar-toggler]')
    const toTop = document.querySelector('[data-js-scroll-to-top]')

    // TODO: Debounce this
    wind.on('scroll', function() {
      const bodyScroll = wind.scrollTop()

      // ---------- nav scroll -----------
      if (bodyScroll > 200) {
        navbar.classList.add('nav-scroll')
      } else {
        navbar.classList.remove('nav-scroll')
      }

      // ---------- to top -----------
      if (bodyScroll > 700) {
        toTop.classList.add('show')
      } else {
        toTop.classList.remove('show')
      }
    })

    navbarNav.addEventListener('click', function(e) {
      if (window.matchMedia('(min-width: 992px)').matches) return
      if (!e.target.closest('.nav-link')) return

      navbarToggler.click()
    })

    // ------- to top -------
    toTop.addEventListener('click', function() {
      $('html, body').animate({ scrollTop: 0 }, 0)
      return false
    })
});

/* ===============================  Preloader page  =============================== */
// $( function() {

//     paceOptions = {
//         ajax: true,
//         document: true,
//         eventLag: false
//     }

//     Pace.on('done', function () {
//         $('#preloader').addClass("isdone")
//         $('.loading').addClass("isdone")
//     })
// } )

// $(window).on('load', function() {
//   document.getElementById('preloader').classList.add('isdone')
// })
